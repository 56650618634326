import React from "react"
import SectionImages from "../components/section-images/section-images";
import SectionList from "../components/section-list/section-list";
import DesignIcon from "../images/file-sketch.svg";
import RotateShape from "../images/rotate-shape.svg";
import Code from "../images/code.svg";
import TestAb from "../images/ab-testing.svg";
import Deploy from "../images/deploy.svg";
import Command from "../images/command.svg";
import DesignValidation from "../images/design-validation.svg";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionTitle from "../components/section-title/section-title";
import ApplicationImages from "../components/application-images/application-images";
import SectionDescription from "../components/section-description/section-description";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Slider from "../components/slider/slider";
import Header from "./../components/header/header";

export const query = graphql`
  query {
    mobile1: file(relativePath: { eq: "mobile1.png" }) {
      childImageSharp {
        fluid(maxWidth: 315, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    desktop1: file(relativePath: { eq: "desktop1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1057, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    mobile2: file(relativePath: { eq: "mobile2.png" }) {
      childImageSharp {
        fluid(maxWidth: 315, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    desktop2: file(relativePath: { eq: "desktop2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1057, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    mobile3: file(relativePath: { eq: "web1.png" }) {
      childImageSharp {
        fluid(maxWidth: 315, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    desktop3: file(relativePath: { eq: "web2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1057, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    slider: file(relativePath: { eq: "jtw-3d.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 499, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    allYaml {
      nodes {
        index {
          seo{
            title,
            description,
            url,
            lang
          },
          realisation1{
            title,
            titleLine2,
            titleStrong,
            description,
            list{
              design,
              mobile,
              development,
              tests
            }
          },
          realisation2{
            title{
              line1,
              line2,
              line3
            },
            description,
            list{
              deploy,
              command,
              code
            }
          },
          realisation3{
            title{
              line1,
              line2,
              line3
            },
            description,
            list{
              design,
              responsive,
              development,
              test
            }
          },
          banner{
            title{
              line1,
              line2,
              line3
            },
            description,
            button{
              text
            }
          },
          columns{
            title{
              line1,
              line2,
              line3
            },
            description,
            items{
              ux{
                title{
                  line1,
                  line2
                },
                description
              },
              webdesign{
                title{
                  line1,
                  line2
                },
                description
              },
              app{
                title{
                  line1,
                  line2
                },
                description
              }
            }
          }
        }
      }
    }
  }
`



const ProjectsPage = ({data}) => {


  const listItems = [
    {
      icon: <DesignIcon/>,
      content: data.allYaml.nodes[0].index.realisation1.list.design
    },
    {
      icon: <RotateShape/>,
      content: data.allYaml.nodes[0].index.realisation1.list.mobile
    },
    {
      icon: <Code/>,
      content: data.allYaml.nodes[0].index.realisation1.list.development
    },
    {
      icon: <TestAb/>,
      content: data.allYaml.nodes[0].index.realisation1.list.tests
    }
  ];

  const listItems2 = [
    {
      icon: <Deploy/>,
      content: data.allYaml.nodes[0].index.realisation2.list.deploy
    },
    {
      icon: <Command/>,
      content: data.allYaml.nodes[0].index.realisation2.list.command
    },
    {
      icon: <Code/>,
      content: data.allYaml.nodes[0].index.realisation2.list.code
    },
  ];

  const listItems3 = [
    {
      icon: <DesignIcon/>,
      content: data.allYaml.nodes[0].index.realisation3.list.design
    },
    {
      icon: <RotateShape/>,
      content: data.allYaml.nodes[0].index.realisation3.list.responsive
    },
    {
      icon: <Code/>,
      content: data.allYaml.nodes[0].index.realisation3.list.development
    },
    {
      icon: <DesignValidation/>,
      content: data.allYaml.nodes[0].index.realisation3.list.test
    },
  ];

  const title = <SectionTitle content={<span>{data.allYaml.nodes[0].index.realisation1.title}&nbsp;{data.allYaml.nodes[0].index.realisation1.titleLine2} <strong>{data.allYaml.nodes[0].index.realisation1.titleStrong}</strong></span>}></SectionTitle>;
  const description = <SectionDescription content={data.allYaml.nodes[0].index.realisation1.description} />;
  const column = <div>{title}{description}<SectionList items={listItems} /></div>

  const title2 = <SectionTitle content={<span>{data.allYaml.nodes[0].index.realisation2.title.line1} {data.allYaml.nodes[0].index.realisation2.title.line2} {data.allYaml.nodes[0].index.realisation2.title.line3}</span>}></SectionTitle>;
  const description2 = <SectionDescription content={data.allYaml.nodes[0].index.realisation2.description} />;
  const column2 = <div>{title2}{description2}<SectionList cssClass="one-column" items={listItems2} /></div>

  const title3 = <SectionTitle content={<span>{data.allYaml.nodes[0].index.realisation3.title.line1} <strong>{data.allYaml.nodes[0].index.realisation3.title.line2}</strong> {data.allYaml.nodes[0].index.realisation3.title.line3}</span>}></SectionTitle>;
  const description3 = <SectionDescription content={data.allYaml.nodes[0].index.realisation3.description} />;
  const column3 = <div>{title3}{description3}<SectionList items={listItems3} /></div>

  return <Layout>
    <SEO 
      title={data.allYaml.nodes[0].index.seo.title}
      description={data.allYaml.nodes[0].index.seo.description}
      url={data.allYaml.nodes[0].index.seo.url}
      lang={data.allYaml.nodes[0].index.seo.lang}
    />
    <Header>
      <Slider 
        image={data.slider.childImageSharp.fluid} 
        textLeft="Wybrane"
        textRight="projekty" />
    </Header>
    <main id="main">
      <SectionImages 
        column={column} 
        images={<ApplicationImages 
          mobile={<Img fluid={data.mobile1.childImageSharp.fluid} />} 
          desktop={<Img fluid={data.desktop1.childImageSharp.fluid} />} 
        />} />
      <SectionImages
        type='primary' 
        column={column2} 
        images={<ApplicationImages 
          mobile={<Img fluid={data.mobile3.childImageSharp.fluid} />} 
          desktop={<Img fluid={data.desktop3.childImageSharp.fluid} />} 
        />} 
      />
      <SectionImages 
        type='tertiary'
        column={column3} 
        images={
          <ApplicationImages 
            mobile={<Img fluid={data.mobile2.childImageSharp.fluid} />} 
            desktop={<Img fluid={data.desktop2.childImageSharp.fluid} />} 
            />} 
      />
    </main>
  </Layout>
}

export default ProjectsPage